import React, { Fragment } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { colors } from '../consts/style';

const Wrapper = styled.div`
  padding: 4rem;
  text-align: center;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: ${colors.orange};
    text-decoration: underline;
  }
`;

export default function Credits() {
  return (
    <Fragment>
      <Helmet>
        <title>Site Credits</title>
      </Helmet>
      <Wrapper>
        <div>
          Website built by Chase Ohlson, a{' '}
          <a href="https://chaseohlson.com">freelance web developer</a> in Los
          Angeles.
        </div>
      </Wrapper>
    </Fragment>
  );
}
